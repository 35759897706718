import * as Sentry from '@sentry/react';

type SentryExtra = Record<string, unknown>;
type SentryTags = Record<string, string>;

export function sentryMessage(
    message: string,
    level: Sentry.SeverityLevel = "log",
    extra?: SentryExtra,
    tags?: SentryTags,
    skipLogging: boolean = false
): string {
    return Sentry.withScope(scope => {
        if (extra) {
            scope.setExtras(extra);
        }
        
        if (tags) {
            scope.setTags(tags);
        }

        scope.setLevel(level);
        const eventId = Sentry.captureMessage(message);

        if (!skipLogging) {
            (console[level as keyof Console] as (...args: any[]) => void)(message, extra);
        }

        return eventId;
    });
}

export function sentryException(
    err: Error,
    level: Sentry.SeverityLevel = "error",
    extra?: SentryExtra,
    tags?: SentryTags,
    skipLogging: boolean = false
): string {
    return Sentry.withScope(scope => {
        if (extra) {
            scope.setExtras(extra);
        }
        
        if (tags) {
            scope.setTags(tags);
        }

        scope.setLevel(level);
        const eventId = Sentry.captureException(err);

        if (!skipLogging) {
            const logMethod = (console[level as keyof Console] as Function) || console.error;
            logMethod(err.message, extra);
        }

        return eventId;
    });
}