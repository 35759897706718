import React, { useContext, useEffect, useState } from 'react'
import { IDriver, IDriverUpdatePayload } from '../../interfaces/Registration'
import { ChevronLeftIcon, PencilSquareIcon } from '@heroicons/react/20/solid'
import Header from './Header'
import {
  RegistrationContext,
  IRegistrationStateValue,
} from './RegistrationBaseWrapper'
import {
  EMAIL_ADDRESS_LABEL_PREVIEW,
  PREVIEW_TITLE,
  DRIVER_DETAILS_CORRECT_LABEL,
  DRIVER_DETAILS_CORRECT_BUTTON_LABEL,
  SCREEN_NAMES,
  DRIVER_EDIT_DETAILS_BUTTON_LABRL,
} from './Constants'
import Capitalize from '../../utils/Capitalize'
import { postRequest } from '../../api'
import formatDate from '../../utils/formatDateHelper'
import { sentryException } from '../../helper/sentry'

type Props = {
  driver: IDriver
  setJuniors: Function
  openEditDriverScreen: Function
  openSuccessScreen: Function
}

const DriverPreviewScreen = (props: Props) => {
  const { driver, openSuccessScreen } = props
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue
  const { orderId, isJunior, setLoading, dispatch, drivers, editMode } =
    registrationContext

  const [enableEditing, setEnableEditing] = useState<boolean>(editMode)
  const [driverInfo, setDriverInfo] = useState<IDriver>(driver)

  const addDriverToRace = async (drivers: IDriver[], orderId: string) => {
    setLoading(true)
    const res = await postRequest('addDriverToBooking', {
      data: {
        drivers: drivers,
        orderId: orderId,
      },
    })
    const resData = res.data.result.drivers
    if (resData[0]) {
      isJunior
        ? dispatch({
            type: 'SET_DRIVERS',
            payload: {
              drivers: [
                ...drivers,
                {
                  dob: resData[0]?.dob,
                  first_name: resData[0]?.first_name,
                  last_name: resData[0]?.last_name,
                } as IDriver,
              ],
            },
          })
        : dispatch({
            type: 'SET_REGISTRATION_DRIVER',
            payload: {
              driver: {
                ...registrationContext.driver,
                dob: resData[0]?.dob,
                first_name: resData[0]?.first_name,
                last_name: resData[0]?.last_name,
              } as IDriver,
            },
          })
    }
    console.log('Recieved res addDriverToBooking: ', resData)
    setLoading(false)
    openSuccessScreen()
  }

  const handleNext = () => {
    const isDriverIsJunior = driver.isJunior
    if (isJunior && isDriverIsJunior) {
      dispatch({
        type: 'SET_CURRENT_NAVIGATION',
        payload: {
          navigation: {
            previousScreen: SCREEN_NAMES.DRIVER_PREVIEW_SCREEN,
            currentScreen: SCREEN_NAMES.JUNIOR_DRIVER_FORM,
          },
        },
      })
      dispatch({
        type: 'SET_EDIT_MODE',
        payload: {
          editMode: true,
        },
      })
      dispatch({
        type: 'SET_DRIVERS',
        payload: {
          drivers: [driver] as IDriver[],
        },
      })
    } else {
      let capitalizeDriver: IDriver =
        driver && driver.guardian_first_name && driver.guardian_last_name
          ? ({
              ...driver,
              first_name: Capitalize(driver.first_name),
              last_name: Capitalize(driver.last_name),
              guardian_first_name: Capitalize(driver.guardian_first_name),
              guardian_last_name: Capitalize(driver.guardian_last_name),
            } as IDriver)
          : ({
              ...driver,
              first_name: Capitalize(driver.first_name),
              last_name: Capitalize(driver.last_name),
            } as IDriver)
      addDriverToRace([capitalizeDriver], orderId)
    }
  }

  useEffect(() => {
    // send reducer action
    if (window.location.pathname.includes('/bookings/') && orderId) {
      const itemRegistrationLink = `${window.location.origin}/bookings/${orderId}`
      dispatch({
        type: 'SET_REGISTRATION_QR_LINK',
        payload: {
          itemRegistrationLink: itemRegistrationLink,
        },
      })
    }
  }, [dispatch, orderId])

  const handleDriverDetailsUpdate = async () => {
    dispatch({
      type: 'SET_LOADING',
      payload: {
        loading: true,
      },
    })

    const extras:Record<string, unknown> = {
      driverInfo: driverInfo
    }

    let driversPayload: IDriverUpdatePayload[] = [
      {
        isJunior: isJunior,
        racefacer_uuid: driverInfo.racefacer_uuid,
        firebase_id: driverInfo.firebase_id,
        email: driverInfo.email,
        data: {
          first_name: Capitalize(driverInfo.first_name),
          last_name: Capitalize(driverInfo.last_name),
        },
      },
    ] as IDriverUpdatePayload[]

    extras.driversPayload = driversPayload

    try {
      const requestURL = 'updateDriversV2'

      const { data } = await postRequest(requestURL, {
        data: {
          drivers: driversPayload,
        },
      })

      extras.response = data

      const newDriverResponse = data?.result?.drivers

      if (newDriverResponse?.length) {
        const updatedDriver: IDriver | undefined = newDriverResponse.find(
          (xDriver: IDriver) => xDriver.email === driverInfo.email
        )

        if (updatedDriver) {
          const mergedDrivers = drivers.map(existingDriver =>
            existingDriver.firebase_id === updatedDriver.firebase_id
              ? {
                  ...existingDriver,
                  ...updatedDriver,
                  dob: formatDate(updatedDriver.dob),
                }
              : existingDriver
          )

          extras.mergedDrivers = mergedDrivers

          dispatch({
            type: 'SET_DRIVERS',
            payload: {
              drivers: mergedDrivers,
            },
          })

          console.log('===== registerDrivers =====', mergedDrivers)
        } else {
          console.warn('No matching driver found in the response.')
        }
      } else {
        console.warn('No drivers returned from the update request.')
      }
    } catch (error) {
      console.error('Error updating driver details:', error)
      sentryException(error as Error, "error", extras, {function: 'handleDriverDetailsUpdate'})
    } finally {
      dispatch({
        type: 'SET_LOADING',
        payload: {
          loading: false,
        },
      })
      setEnableEditing(false)
      dispatch({
        type: 'SET_EDIT_MODE',
        payload: {
          editMode: false,
        },
      })
      if (isJunior) {
        dispatch({
          type: 'SET_CURRENT_NAVIGATION',
          payload: {
            navigation: {
              currentScreen: SCREEN_NAMES.DRIVER_LIST,
              previousScreen: SCREEN_NAMES.DRIVER_PREVIEW_SCREEN,
            },
          },
        })
      }
    }
  }

  return (
    <>
      <button
        className="text-[#5B36DF] font-semibold absolute top-4 left-5 flex items-center"
        onClick={() =>
          dispatch({
            type: 'SET_CURRENT_NAVIGATION',
            payload: {
              navigation: {
                currentScreen: SCREEN_NAMES.DRIVER_LIST,
                previousScreen: SCREEN_NAMES.DRIVER_PREVIEW_SCREEN,
              },
            },
          })
        }
      >
        <ChevronLeftIcon className="w-[22px] h-[22px]" /> Go Back
      </button>
      <Header title={isJunior ? 'Existing Guardian Profile' : PREVIEW_TITLE} />
      <div className="mb-5">
        <div className="text-base font-semibold	mb-2.5">
          {EMAIL_ADDRESS_LABEL_PREVIEW}
        </div>
        <input
          type="text"
          name="email"
          className="border border-slate-200 rounded-lg py-3 px-4 outline-0 text-base text-slate-500 w-full"
          value={driver.email}
          disabled
        />
      </div>

      {isJunior && <Header title={'Children Details'} />}

      <div className="mb-3.5 text-gray-500 text-base font-normal">
        {enableEditing
          ? DRIVER_EDIT_DETAILS_BUTTON_LABRL
          : DRIVER_DETAILS_CORRECT_LABEL}
      </div>
      {enableEditing ? (
        <div className="flex justify-between items-start gap-2 w-full my-4">
          <div className="flex flex-col gap-2 w-full">
            <label className="text-base w-full text-[#2F1160] font-[500] md:font-[700]">
              First name
            </label>
            <input
              name="driver_first_name"
              type="text"
              value={driverInfo.first_name || ''}
              onChange={event =>
                setDriverInfo((prevState: IDriver) => ({
                  ...prevState,
                  first_name: event.target.value,
                }))
              }
              placeholder="First Name"
              className={`border rounded-lg py-3 px-4 outline-0 capitalize text-base`}
            />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <label className="text-base w-full text-[#2F1160] font-[500] md:font-[700]">
              Last name
            </label>
            <input
              name="driver_last_name"
              type="text"
              value={driverInfo.last_name || ''}
              onChange={event =>
                setDriverInfo((prevState: IDriver) => ({
                  ...prevState,
                  last_name: event.target.value,
                }))
              }
              placeholder="Last Name"
              className={`border rounded-lg py-3 px-4 outline-0 capitalize text-base`}
            />
          </div>
        </div>
      ) : (
        <div className="outline-0 px-4 py-2 text-base border rounded-xl w-full resize-none mb-5 capitalize flex justify-between items-center">
          <div>{`${driverInfo.first_name} ${driverInfo.last_name}`}</div>
          <div
            className="text-[#5B36DF] flex flex-col justify-center items-center cursor-pointer"
            onClick={() => setEnableEditing(true)}
          >
            <PencilSquareIcon className="w-[22px] h-[22px]" />
            <span
              className="text-[#5B36DF] text-[10px]"
              style={{ lineHeight: 1 }}
            >
              Edit Details
            </span>
          </div>
        </div>
      )}

      <div className="flex flex-col items-center gap-2">
        <div className="flex justify-between items-center gap-1 w-full">
          {enableEditing && (
            <>
              <button
                className="relative w-full bg-black text-white font-semibold py-[15px] px-[25px] text-[16px] rounded-full"
                onClick={() => {
                  setDriverInfo((prevState: IDriver) => ({
                    ...prevState,
                    first_name: driver.first_name,
                    last_name: driver.last_name,
                  }))
                  setEnableEditing(false)
                  dispatch({
                    type: 'SET_EDIT_MODE',
                    payload: {
                      editMode: false,
                    },
                  })
                  if (isJunior) {
                    dispatch({
                      type: 'SET_CURRENT_NAVIGATION',
                      payload: {
                        navigation: {
                          currentScreen: SCREEN_NAMES.DRIVER_LIST,
                          previousScreen: SCREEN_NAMES.DRIVER_PREVIEW_SCREEN,
                        },
                      },
                    })
                  }
                }}
              >
                Cancel update
              </button>

              <button
                className="w-fit self-center items-center bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] p-2 font-semibold py-[15px] px-[25px] text-[16px] rounded-full w-full text-white"
                onClick={handleDriverDetailsUpdate}
              >
                Save changes
              </button>
            </>
          )}
        </div>
        {!enableEditing && (
          <button
            className="w-fit self-center items-center bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] p-2 font-semibold py-[15px] px-[25px] text-[16px] rounded-full w-full text-white"
            onClick={() => handleNext()}
          >
            {DRIVER_DETAILS_CORRECT_BUTTON_LABEL}
          </button>
        )}
      </div>
    </>
  )
}

export default DriverPreviewScreen
