import React, { useContext, useMemo } from 'react'
import { IDriver } from '../../interfaces/Registration'
import QrCode from 'qrcode.react'

import {
  BOOKING_REGISTRATION_COMPLETED_DESC,
  BOOKING_REGISTRATION_COMPLETED_SUBTEXT,
  BOOKING_REGISTRATION_COMPLETED_TITLE,
  GENERIC_REGISTRATION_COMPLETED_STAFF_SUBTEXT,
  GENERIC_REGISTRATION_COMPLETED_TITLE,
} from './Constants'
import {
  RegistrationContext,
  IRegistrationStateValue,
} from './RegistrationBaseWrapper'

type Props = {
  driver: IDriver
  drivers: IDriver[]
  openGenericRegistrationScreen: Function
}

const SuccessScreen = (props: Props) => {
  const { driver, drivers } = props
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue
  const { isJunior, isGenericDriverRegistration, itemRegistrationLink } =
    registrationContext

  const RegistrationCompleteTitleBlock = (
    <div className="text-black text-center text-[20px] md:text-[30px] font-[700]">
      {isGenericDriverRegistration
        ? GENERIC_REGISTRATION_COMPLETED_TITLE
        : BOOKING_REGISTRATION_COMPLETED_TITLE}
    </div>
  )

  const filterJuniorDrivers: IDriver[] = useMemo(
    () =>
      drivers.filter(
        (junior: IDriver) => junior.first_name && junior.last_name && junior.dob
      ),
    [drivers]
  ) as IDriver[]

  return (
    <>
      {isJunior ? (
        <>
          <div className="text-black text-center text-[20px] md:text-[30px] font-[700]">
            {GENERIC_REGISTRATION_COMPLETED_TITLE}
          </div>
          <p className="text-[#777] text-center text-[14px] font-[500]">
            thank you for your registration
          </p>
          <p className="text-center text-[#555758] font-[600] text-[20px] my-[20px] capitalize">
            {isJunior && drivers && drivers.length
              ? drivers[0].guardian_first_name
              : registrationContext.driver &&
                registrationContext.driver.guardian_first_name}{' '}
            {isJunior && drivers && drivers.length
              ? drivers[0].guardian_last_name
              : registrationContext.driver &&
                registrationContext.driver &&
                registrationContext.driver.guardian_last_name}
          </p>
          <div className="bg-[#F3F4F7] my-[10px] overflow-hidden rounded-[15px]">
            <div className="text-center text-[#555758] text-[20px] font-[600] w-full bg-[#EBECEF] py-[15px]">
              Total Registered
            </div>
            <div className="bg-[#9D62FE] rounded-full flex justify-center items-center w-[100px] h-[100px] opacity-[0.5] mx-auto my-[15px]">
              <span className="text-[32px] text-white font-[700]">
                {filterJuniorDrivers.length}/10
              </span>
            </div>
            <div className="mb-[30px]">
              {drivers?.map((junior, idx) => (
                <div
                  key={idx}
                  className="font-[600] text-[14px] sm:text-[16px] text-[#777] text-center"
                >
                  {junior.first_name} {junior.last_name}
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          {itemRegistrationLink &&
          !itemRegistrationLink.includes('undefined') ? (
            <>
              <h2 className="text-center text-[20px] md:text-[30px] font-[700]">
                Registration Completed
              </h2>
              <p className="text-center text-[#777] text-[14px] md:px-[60px]">
                If there are other racers in your session who have not yet
                registered, please register using the following QR code:
              </p>
            </>
          ) : (
            RegistrationCompleteTitleBlock
          )}
          <div className="sm:mb-1 mb-1.5 text-xl font-normal text-base text-gray-500 text-center">
            {itemRegistrationLink &&
              !itemRegistrationLink.includes('undefined') && (
                <div className="flex justify-center items-center my-[30px]">
                  <QrCode value={itemRegistrationLink} size={248} />
                </div>
              )}
            {itemRegistrationLink &&
            !itemRegistrationLink.includes('undefined') ? (
              <div className="text-center text-[#777] mb-[30px]">
                <p>
                  {registrationContext?.driver?.first_name}{' '}
                  {registrationContext?.driver?.last_name}
                </p>
                {registrationContext?.driver?.address && (
                  <p>{registrationContext?.driver?.address}</p>
                )}

                {registrationContext?.driver?.phone_number && (
                  <p>({registrationContext?.driver?.phone_number})</p>
                )}
              </div>
            ) : isGenericDriverRegistration ? (
              `${driver.first_name} ${driver.last_name}`
            ) : (
              BOOKING_REGISTRATION_COMPLETED_DESC
            )}
          </div>
        </>
      )}
      {!itemRegistrationLink &&
        !itemRegistrationLink.includes('undefined') &&
        !isJunior && (
          <>
            <div className="text-base font-normal sm:text-center mb-[20px]">
              {isGenericDriverRegistration
                ? GENERIC_REGISTRATION_COMPLETED_STAFF_SUBTEXT
                : BOOKING_REGISTRATION_COMPLETED_SUBTEXT}
            </div>
          </>
        )}
    </>
  )
}

export default SuccessScreen
