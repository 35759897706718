import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  RegistrationContext,
  IRegistrationStateValue,
} from './RegistrationBaseWrapper'
import Header from './Header'
import {
  DRIVER_EXISTING_PROFILE_TITLE,
  BACK_BUTTON_LABEL,
  CREATE_NEW_USER_LABEL,
  SCREEN_NAMES,
} from './Constants'
import {
  PlusIcon,
  ChevronRightIcon,
  PencilSquareIcon,
  ChevronLeftIcon,
} from '@heroicons/react/20/solid'
import DriverListItem from './DriverListItem'
import {
  IDriver,
  IGuardian,
  IGuardianUpdatePayload,
} from '../../interfaces/Registration'
import { postRequest } from '../../api'
import Capitalize from '../../utils/Capitalize'
import { convertDob } from '../../utils/RegistrationHelpers'
import { sentryException } from '../../helper/sentry'
type Props = {
  goToPreviousPage: Function
  openCreateDriverScreen: Function
  openDriverPreview: Function
}
// revert
const DriverListScreen = (props: Props) => {
  const { openCreateDriverScreen, openDriverPreview } = props
  const registrationContext = useContext(RegistrationContext)
  const { drivers, isJunior, dispatch } =
    registrationContext as IRegistrationStateValue
  const [enableEditing, setEnableEditing] = useState<boolean>(false)
  const [guardianInfo, setGuardianInfo] = useState<IGuardian>({} as IGuardian)

  useEffect(() => {
    if (!(drivers && drivers.length)) {
      openCreateDriverScreen()
    }
  }, [drivers, openCreateDriverScreen])

  const getFirstAvailableGuardianInfo: IGuardian | undefined = useMemo(() => {
    if (drivers && drivers.length) {
      const findGuardianInfoFromChild: IGuardian | null =
        drivers
          .map((xDriver: IDriver) =>
            xDriver.guardian_id &&
            xDriver.firebase_id &&
            xDriver.email &&
            xDriver.racefacer_uuid &&
            xDriver.guardian_first_name &&
            xDriver.guardian_last_name
              ? ({
                  email: xDriver.email,
                  guardian_id: xDriver.guardian_id,
                  guardian_first_name: xDriver.guardian_first_name,
                  guardian_last_name: xDriver.guardian_last_name,
                } as IGuardian)
              : null
          )
          .filter((guardian): guardian is IGuardian => guardian !== null)[0] ||
        null

      if (findGuardianInfoFromChild) {
        setGuardianInfo(findGuardianInfoFromChild)
      }
      return findGuardianInfoFromChild
    }
  }, [drivers])

  const handleDriverDetailsUpdate = async () => {
    dispatch({
      type: 'SET_LOADING',
      payload: {
        loading: true,
      },
    })

    const extras: Record<string, unknown> = {
      drivers: drivers,
      guardianInfo: guardianInfo,
    }

    try {
      const getDriversIds: { racefacer_uuid: string; firebase_id: string }[] =
        drivers
          .map((xDriver: IDriver) =>
            xDriver.firebase_id && xDriver.racefacer_uuid
              ? {
                  firebase_id: xDriver.firebase_id,
                  racefacer_uuid: xDriver.racefacer_uuid,
                }
              : null
          )
          .filter(
            (
              driver
            ): driver is { firebase_id: string; racefacer_uuid: string } =>
              driver !== null
          )

      let guardianPayload: IGuardianUpdatePayload = {
        guardian_id: guardianInfo.guardian_id,
        email: guardianInfo.email,
        data: {
          ...guardianInfo,
          guardian_first_name: Capitalize(
            guardianInfo.guardian_first_name as string
          ),
          guardian_last_name: Capitalize(
            guardianInfo.guardian_last_name as string
          ),
        },
        childDrivers: getDriversIds,
      } as IGuardianUpdatePayload

      extras.guardianPayload = guardianPayload

      const requestURL = 'updateGuardian'

      const { data } = await postRequest(requestURL, {
        data: {
          guardian: guardianPayload,
        },
      })
      extras.response = data
      const { result } = data
      if (result && result.drivers) {
        let updatedDrivers = result.drivers.map((uDriver: any) => ({
          ...uDriver,
          dob: convertDob(uDriver.dob),
        }))

        dispatch({
          type: 'SET_DRIVERS',
          payload: {
            drivers: updatedDrivers as IDriver[],
          },
        })
      }
      // console.log('==== update return data ====', result.drivers)
    } catch (error) {
      console.error('Error updating driver details:', error)
      sentryException(error as Error, 'error', extras, {
        function: 'handleDriverDetailsUpdate',
      })
    } finally {
      dispatch({
        type: 'SET_LOADING',
        payload: {
          loading: false,
        },
      })
      setEnableEditing(false)
    }
  }

  if (!drivers.length) {
    return dispatch({
      type: 'SET_CURRENT_NAVIGATION',
      payload: {
        navigation: {
          currentScreen: SCREEN_NAMES.DRIVER_FORM_ONLY,
          previousScreen: SCREEN_NAMES.EMAIL_SELECTION_SCREEN,
        },
      },
    })
  }

  return (
    <>
      <button
        className="text-[#5B36DF] font-semibold absolute top-4 left-5 flex items-center"
        onClick={() =>
          dispatch({
            type: 'SET_CURRENT_NAVIGATION',
            payload: {
              navigation: {
                currentScreen: SCREEN_NAMES.EMAIL_SELECTION_SCREEN,
                previousScreen: SCREEN_NAMES.DRIVER_LIST,
              },
            },
          })
        }
      >
        <ChevronLeftIcon className="w-[22px] h-[22px]" /> {BACK_BUTTON_LABEL}
      </button>
      <Header title={DRIVER_EXISTING_PROFILE_TITLE}></Header>
      {isJunior && drivers.length && (
        <div className="pt-3 pr-5 pl-4 pb-3 bg-white border border-slate-200 rounded-lg hover:shadow mb-2.5">
          <div className="flex flex-col lg:flex-row gap-5 items-center justify-between">
            <div
              className="flex items-center gap-3.5 cursor-pointer"
              onClick={() =>
                setEnableEditing((prevState: boolean) => !prevState)
              }
            >
              <div className="text-base leading-5 capitalize">
                {drivers[0].guardian_first_name} {drivers[0].guardian_last_name}
              </div>

              {!enableEditing && (
                <div className="text-[#5B36DF] rounded-full mr-2.5 flex flex-col items-center justify-center cursor-pointer">
                  <PencilSquareIcon className="w-[22px] h-[22px]" />
                  <span className="text-[#5B36DF] text-[10px]">
                    Edit Guardian Details
                  </span>
                </div>
              )}
            </div>
            <div className="flex items-center gap-3.5 cursor-pointer">
              <p className="text-base">Number of Children ({drivers.length})</p>
            </div>
          </div>

          {isJunior && drivers && drivers.length && !enableEditing && (
            <div className="border border-slate-200 rounded-lg my-5 mx-1 p-2">
              {drivers.map((xDriver: IDriver) => {
                return (
                  <DriverListItem
                    driver={xDriver}
                    isJunior={true}
                    openDriverPreview={openDriverPreview}
                    enableEditing={enableEditing}
                    key={xDriver.firebase_id}
                  />
                )
              })}
            </div>
          )}

          {isJunior && enableEditing && (
            <div className="border border-slate-200 rounded-lg my-5 mx-1 p-2">
              <div className="flex justify-between items-start gap-2 w-full my-4">
                <div className="flex flex-col gap-2 w-full">
                  <label className="text-base w-full text-[#2F1160] font-[500] md:font-[700]">
                    First name
                  </label>
                  <input
                    name="driver_first_name"
                    type="text"
                    value={guardianInfo.guardian_first_name || ''}
                    onChange={event =>
                      setGuardianInfo((prevState: IGuardian) => ({
                        ...prevState,
                        guardian_first_name: event.target.value,
                      }))
                    }
                    placeholder="First Name"
                    className={`border rounded-lg py-3 px-4 outline-0 capitalize text-base`}
                  />
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <label className="text-base w-full text-[#2F1160] font-[500] md:font-[700]">
                    Last name
                  </label>
                  <input
                    name="driver_last_name"
                    type="text"
                    value={guardianInfo.guardian_last_name || ''}
                    onChange={event =>
                      setGuardianInfo((prevState: IGuardian) => ({
                        ...prevState,
                        guardian_last_name: event.target.value,
                      }))
                    }
                    placeholder="Last Name"
                    className={`border rounded-lg py-3 px-4 outline-0 capitalize text-base`}
                  />
                </div>
              </div>

              <div className="flex justify-between items-center gap-1 w-full">
                {enableEditing && (
                  <>
                    <button
                      className="relative w-full bg-black text-white font-semibold py-[15px] px-[25px] text-[16px] rounded-full"
                      onClick={() => {
                        if (getFirstAvailableGuardianInfo) {
                          setGuardianInfo((prevState: IGuardian) => ({
                            ...prevState,
                            guardian_first_name:
                              getFirstAvailableGuardianInfo.guardian_first_name,
                            guardian_last_name:
                              getFirstAvailableGuardianInfo.guardian_last_name,
                          }))
                        }
                        setEnableEditing(false)
                      }}
                    >
                      Cancel update
                    </button>

                    <button
                      className="w-fit self-center items-center bg-[linear-gradient(290deg,#F908FF_0%,#D32EFE_8.19%,#37CCFB_87.88%,#05FFFA_100%)] p-2 font-semibold py-[15px] px-[25px] text-[16px] rounded-full w-full text-white"
                      onClick={handleDriverDetailsUpdate}
                    >
                      Save changes
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {!isJunior &&
        drivers &&
        drivers.length &&
        drivers.map((driver, idx) => (
          <DriverListItem
            driver={driver}
            openDriverPreview={openDriverPreview}
            key={idx}
          />
        ))}
      {!isJunior && (
        <div
          className="pt-3 pr-5 pl-4 pb-3 bg-white border border-slate-200 rounded-lg flex items-center justify-between hover:shadow mb-2.5 cursor-pointer"
          onClick={() => {
            openCreateDriverScreen()
          }}
        >
          <div className="flex items-center">
            <div className="w-[50px] h-[50px] bg-zinc-300 rounded-full mr-2.5 flex items-center justify-center">
              <PlusIcon fill="black" className="w-[24px] h-[24px]" />
            </div>
            <div>
              <div className="text-base leading-5">{CREATE_NEW_USER_LABEL}</div>
            </div>
          </div>
          <div className="">
            <div className="w-[24px] h-[24px] bg-[#5B36DF] rounded-full flex items-center justify-center">
              <ChevronRightIcon fill="white" className="w-[16px] h-[16px]" />
            </div>
          </div>
        </div>
      )}

      {isJunior && (
        <div className="flex items-center justify-between">
          <button
            className="text-center self-center w-full items-center p-2 border border-slate-200 text-medium font-semibold rounded shadow-sm. hover:bg-indigo-700 text-white bg-indigo-600"
            onClick={() =>
              dispatch({
                type: 'SET_CURRENT_NAVIGATION',
                payload: {
                  navigation: {
                    currentScreen: SCREEN_NAMES.JUNIOR_DRIVER_FORM,
                    previousScreen: SCREEN_NAMES.DRIVER_LIST,
                  },
                },
              })
            }
          >
            NEXT
          </button>
        </div>
      )}
    </>
  )
}

export default DriverListScreen
