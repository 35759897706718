import React, { useContext } from 'react'
import { DateTime } from 'luxon'
import { IDriver } from '../../interfaces/Registration'
import { ChevronRightIcon, PencilSquareIcon } from '@heroicons/react/20/solid'
import {
  IRegistrationStateValue,
  RegistrationContext,
} from './RegistrationBaseWrapper'

type Props = {
  driver: IDriver
  openDriverPreview: Function
  isJunior?: boolean
  enableEditing?: boolean
}

const DriverListItem = (props: Props) => {
  const registrationContext = useContext(
    RegistrationContext
  ) as IRegistrationStateValue

  const { dispatch } = registrationContext

  const { driver, openDriverPreview, isJunior, enableEditing } = props
  const age = Math.abs(
    Math.ceil(
      DateTime.fromFormat(driver.dob, 'dd-mm-yyyy').diffNow('years').years
    )
  )
  return (
    <>
      <div
        className={`pt-3 pr-5 pl-4 pb-3 bg-white ${
          isJunior
            ? 'border-b border-b-slate-200'
            : 'border border-slate-200 cursor-pointer'
        } rounded-lg flex items-center justify-between hover:shadow mb-2.5`}
        onClick={() => {
          if (!isJunior && !enableEditing) {
            openDriverPreview(driver)
          }
        }}
      >
        <div>
          <div className="text-base leading-5 mb-[5px] sm:text-clip text-ellipsis overflow-hidden truncate sm:w-full w-[90px]">
            {`${driver.first_name} ${driver.last_name}`}
          </div>
          <div className="leading-4 text-sm">{age} Year Old</div>
        </div>
        {isJunior && (
          <div
            className="text-[#5B36DF] flex flex-col justify-center items-center cursor-pointer"
            onClick={() => {
              dispatch({
                type: 'SET_EDIT_MODE',
                payload: {
                  editMode: true,
                },
              })
              openDriverPreview(driver)
            }}
          >
            <PencilSquareIcon className="w-[22px] h-[22px]" />
            <span className="text-[#5B36DF] text-[10px]">
              Edit Child Details
            </span>
          </div>
        )}

        {!isJunior && !enableEditing && (
          <div className="w-[24px] h-[24px] bg-[#5B36DF] rounded-full flex items-center justify-center">
            <ChevronRightIcon fill="white" className="w-[16px] h-[16px]" />
          </div>
        )}
      </div>
    </>
  )
}

export default DriverListItem
